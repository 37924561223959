import Tooltip from '@mui/material/Tooltip'
import { CommonButtonProps, StandardButton } from './StandardButton'
import { BreakoutIcon, BreakoutActiveIcon } from 'icons'
import { useState } from 'react'

const BreakoutButton: React.FC<CommonButtonProps> = ({ id, active, disabled, onClick }) => {
    const [isTooltipOpen, setTooltipOpen] = useState(false)

    return (
        <Tooltip
            placement='bottom'
            arrow
            className='tooltip-breakout'
            PopperProps={{
                className: 'tooltip-breakouts',
                sx: {
                    '.MuiTooltip-tooltip': {
                        maxWidth: '300px',
                        borderRadius: '8px',
                        backgroundColor: '#2D3748',
                        color: '#FFFFFF',
                        display: 'flex',
                        textAlign: 'center',
                        lineHeight: '18px',
                        '.MuiTooltip-arrow': {
                            color: '#2D3748'
                        }
                    }
                }
            }}
            open={isTooltipOpen}
            title='There are not enough students to start breakout rooms. 4 or more students are required to start breakout rooms'
            disableInteractive
        >
            <span
                onMouseEnter={() => disabled && setTooltipOpen(true)}
                onMouseLeave={() => disabled && setTooltipOpen(false)}
            >
                <StandardButton
                    id={id}
                    color={active ? 'primary' : 'secondary'}
                    iconSrc={active ? BreakoutActiveIcon : BreakoutIcon}
                    disabled={disabled}
                    onClick={onClick}
                    text='Breakout Rooms'
                />
            </span>
        </Tooltip>
    )
}

export { BreakoutButton }
