import { styled } from '@mui/material'
import { ExpandCCDialogComponent } from './dialog'

const CueCardImageWrapper = styled('img')(() => ({
    width: '520px',
    height: '286px',
    border: 'solid 1px rgba(0, 0, 0, 0.14)',
    borderRadius: '4px'
}))

export interface ViewCueCardImage {
    name: string
    url: string
}

type ViewCueCardsProps = {
    close: () => void
    imageURL: string
    imageName?: string
}

const ViewCueCardsModal: React.FC<ViewCueCardsProps> = ({ close, imageURL, imageName }) => {
    return (
        <>
            <ExpandCCDialogComponent
                open
                onClose={close}
                title={imageName ?? 'Cue Cards'}
                actions={[]}
                maxWidth={'sm'}
                showCloseIcon
            >
                <CueCardImageWrapper src={imageURL} alt='cue card image' />
            </ExpandCCDialogComponent>
        </>
    )
}

export { ViewCueCardsModal }
