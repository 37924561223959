import { styled } from '@mui/material'
import { CustomDialogComponent } from 'hello-design-system'

const DialogComponent = styled(CustomDialogComponent)(() => (
    {
        '& .MuiDialog-paper': {
            maxWidth: '600px !important',
            minWidth: '600px !important'
        },
        '& .MuiDialogActions-root': {
            padding: '24px 40px !important'
        },
        '& .MuiDialogContent-root': {
            padding: '0 24px !important'
        },
        '& .MuiTypography-h6': {
            textTransform: 'unset !important',
            fontWeight: 700,
            fontSize: '18px'
        }
    }
))

const CCinBRFlowDialogComponent = styled(DialogComponent)<{ isAllCueCardSelected: boolean }>(({ isAllCueCardSelected }) => (
    {
        '.MuiButton-containedPrimary': {
            borderRadius: '8px !important',
            backgroundColor: isAllCueCardSelected ? '#003359' : '#71809633',
            cursor: isAllCueCardSelected ? 'pointer' : 'not-allowed',
            color: isAllCueCardSelected ? '#FFFFFF' : '#919EABCC'
        },
        '.MuiButton-outlinedPrimary': {
            color: '#003359 !important',
            fontWeight: 700,
            fontSize: '15px'
        }
    }
))

const BreakoutRoomDialogComponent = styled(CCinBRFlowDialogComponent)(() => (
    {
        '& .MuiTypography-h6': {
            padding: '17px !important'
        },
        '& .MuiButton-outlined': {
            border: '1px solid',
            borderRadius: '8px'
        },
        '.MuiButton-containedPrimary': {
            fontWeight: '700 !important',
            fontSize: '15px !important'
        },
        '.MuiButton-outlinedPrimary': {
            '&:first-of-type': {
                marginRight: 'auto'
            }
        }
    }
))

const ShareCCDialogComponent = styled(CCinBRFlowDialogComponent)(() => (
    {
        '& .MuiTypography-h6': {
            textTransform: 'unset !important',
            fontWeight: 700,
            fontSize: '18px',
            padding: '17px !important'
        }
    }
))

const CCBRExitDialogComponent = styled(DialogComponent)(() => (
    {
        '& .MuiTypography-h6': {
            color: '#2D3748 !important',
            '&.MuiDialogTitle-root': {
                padding: '34px 24px !important'
            }
        },
        '.MuiButton-containedPrimary': {
            borderRadius: '8px !important',
            fontWeight: '700 !important',
            fontSize: '15px !important'
        },
        '.MuiButton-outlinedPrimary': {
            border: '1px solid !important',
            borderRadius: '8px !important',
            color: '#003359 !important',
            fontWeight: '700 !important',
            fontSize: '15px !important'
        },
        '& .MuiDialogActions-root': {
            padding: '24px 40px!important'
        }
    }
))

const CCSuggestDialogComponent = styled(DialogComponent)(() => (
    {
        '& .MuiTypography-h6': {
            color: '#2D3748 !important',
            '&.MuiDialogTitle-root': {
                padding: '34px 24px !important'
            }
        },
        '.MuiButton-containedPrimary': {
            borderRadius: '8px !important',
            fontWeight: '700 !important',
            fontSize: '15px !important'
        },
        '.MuiButton-outlinedPrimary': {
            borderRadius: '8px !important',
            color: '#003359 !important',
            fontWeight: '700 !important',
            fontSize: '15px !important'
        }
    }
))

const ExpandCCDialogComponent = styled(DialogComponent)(() => (
    {
        '& .MuiTypography-h6': {
            padding: '17px !important'
        }
    }
))

export { BreakoutRoomDialogComponent, ShareCCDialogComponent, CCBRExitDialogComponent, CCSuggestDialogComponent, ExpandCCDialogComponent }
