import { useContext, useEffect, useState } from 'react'
import { ConferenceContext } from 'contexts/conference'
import { WaitingForOthers } from 'components/status-panels'
import { BorZoomLayout, ZoomLayout } from 'components/participants'
import { ViewCueCardImage, ViewCueCardsModal } from './components/ViewCueCardsModal'
import { ShareCueCardsModal } from './components'
import { BreakoutRoomsState } from 'pages/useBreakouts'

const CueCardsView: React.FC<{ breakouts?: BreakoutRoomsState }> = ({ breakouts }) => {
    const [viewShareCueCardModal, setShareCueCardModalVisibility] = useState<boolean>(false)
    const [zoomedCueCardImage, setZoomedCueCardImage] = useState<ViewCueCardImage>({ name: '', url: '' })
    const conference = useContext(ConferenceContext)

    useEffect(() => {
        if (conference.status === 'session-joined') {
            conference.primus.send('change-screen', { screen: 'cue-card' })
        }
    }, [])

    const getZoomedCueCard = (imageUrl: ViewCueCardImage): void => {
        setZoomedCueCardImage(imageUrl)
    }

    return (
        <>
            <WaitingForOthers />
            {breakouts?.isActive
                ? <BorZoomLayout
                    isCueCard
                    breakouts={breakouts}
                    cueCardZoomModal={data => getZoomedCueCard(data)}
                    showCueCardSharingModal={() => setShareCueCardModalVisibility(true)} />
                : <ZoomLayout
                    isCueCards
                    cueCardZoomModal={data => getZoomedCueCard(data)}
                    showCueCardSharingModal={() => setShareCueCardModalVisibility(true)}
                />
            }

            {(zoomedCueCardImage.url) ? (
                <ViewCueCardsModal
                    imageURL={zoomedCueCardImage.url}
                    imageName={zoomedCueCardImage.name}
                    close={() => setZoomedCueCardImage({ name: '', url: '' })} />
            ) : null}

            {viewShareCueCardModal ? (
                <ShareCueCardsModal
                    setZoomedCueCardImage={setZoomedCueCardImage}
                    breakouts={breakouts}
                    active
                    close={() => setShareCueCardModalVisibility(false)}
                />
            ) : null}
        </>
    )
}

export { CueCardsView }
