import { ConferenceParticipant } from 'contexts/conference/types'
import { StudentRoomMappingType } from './BreakoutRoomsModal'
import { Action, IconNames } from 'hello-design-system'
import { ParticipantData } from '../cue-cards/type'

export const studentRoomMapping = (conferenceParticipants: ConferenceParticipant[]): StudentRoomMappingType => {
    return new Map(conferenceParticipants.map(student => [
        student.wseUserId,
        {
            wseProfilePhotoUrl: student.wseProfilePhotoUrl,
            firstName: student.firstName,
            lastName: student.lastName,
            id: student.wseUserId,
            indexId: 0
        }
    ]))
}

export const fetchButtonData = (
    label: string,
    variant: 'text' | 'outlined' | 'contained',
    handleActionClick: () => void,
    icon?: IconNames,
    iconPosition?: 'start' | 'end'
): Action => ({
    label,
    onClick: handleActionClick,
    variant,
    color: 'primary',
    icon,
    iconPosition
})

export const getMode = (pathname: string): 'speak' | 'cue-cards' | 'slides' | 'whiteboard' => {
    if (pathname.includes('/teacher/classroom/cue-cards')) return 'cue-cards'
    if (pathname.includes('/teacher/classroom/slides')) return 'slides'
    if (pathname.includes('/teacher/classroom/whiteboard')) return 'whiteboard'
    return 'speak'
}

export const numberToAlphabet = (number: number): string => {
    if (number >= 1 && number <= 26) {
        return String.fromCharCode(64 + number)
    } else {
        return 'Invalid input'
    }
}

export const sortingArray = (studentData: ParticipantData[]): ParticipantData[] => {
    const sortedStudentData = studentData.sort((a: { firstName: string }, b: { firstName: string }) =>
        a.firstName.localeCompare(b.firstName, 'en', { sensitivity: 'base' }))
    const uniqueStudentData = sortedStudentData.filter(
        (student, index, sortedStudentArray) => index === sortedStudentArray.findIndex(sortedStudent => sortedStudent.id === student.id)
    )
    return uniqueStudentData
}
