import { useContext } from 'react'
import { styled, Button, ButtonGroup } from '@mui/material'
import { ConferenceContext } from 'contexts/conference'
import { SettingsMenu } from 'components/SettingsMenu'
import { ButtonWrapper, ClassButton, BreakoutButton, EndCallButton } from 'components/buttons'
import { SettingsIconButton } from 'components/icon-buttons'
import { BreakoutRoomsState } from 'pages/useBreakouts'
import { Timer } from 'components/timer'

const HeaderWrapper = styled('div')(({ theme }) => ({
    boxSizing: 'content-box',
    display: 'flex',
    padding: '0 var(--page-side-margin)',
    gap: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        padding: 'var(--page-side-margin)'
    }
}))
const LeftWrapper = styled('div')<{ borActive?: boolean }>(({ theme, borActive }) => ({
    backgroundColor: theme.palette.neutral.main,
    padding: 'var(--page-v-margin) 0',
    flex: 2,
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'start',
    gap: theme.spacing(1),
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
        flexDirection: borActive ? 'column' : 'row'
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: 0,
        justifyContent: 'center'
    }
}))
const BreakoutButtonsWrapper = styled('div')(({ theme }) => ({
    display: 'flex'
}))
const CenterWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
}))
const RightWrapper = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.neutral.main,
    padding: 'var(--page-v-margin) 0',
    flex: 2,
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'end',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: 0,
        justifyContent: 'center'
    }
}))
const OverseeButton = styled(Button)(({ theme }) => ({
    border: `solid 1px ${theme.palette.neutral.hover}`,
    '&:hover': {
        border: `solid 1px ${theme.palette.neutral.hover}`
    }
}))

type ClassMode = {
    breakouts?: BreakoutRoomsState
    viewBorModal?: boolean
    showBorModal?: () => void
    showEditBorModal?: () => void
    toggleLeaveDialog?: () => void
    showExitBORPopup?: () => void
}

const TopControls: React.FC<ClassMode> = ({ breakouts, viewBorModal, showBorModal, showEditBorModal, toggleLeaveDialog, showExitBORPopup }) => {
    const conference = useContext(ConferenceContext)

    const sessionJoined = conference.status === 'session-joined'

    const onClassButtonClick = (): void => {
        if (breakouts?.isActive && showExitBORPopup) {
            showExitBORPopup()
        } else {
            breakouts?.stop()
        }
    }

    const activeStudentsCount = (): number => conference.participants.filter(s => s.role !== 'teacher').length

    return (
        <HeaderWrapper>
            <LeftWrapper borActive={breakouts?.isActive}>
                <ButtonWrapper>
                    <ButtonGroup>
                        <ClassButton
                            id='class-button'
                            active={!breakouts?.isActive}
                            disabled={!sessionJoined}
                            onClick={!breakouts?.isActive ? () => null : onClassButtonClick}
                        />
                        <BreakoutButton
                            id='breakout-rooms-button'
                            active={!!breakouts?.isActive}
                            disabled={!sessionJoined || activeStudentsCount() < 3}
                            onClick={breakouts?.isActive ? undefined : showBorModal}
                        />
                    </ButtonGroup>
                </ButtonWrapper>
                {breakouts?.isActive ? (
                    <BreakoutButtonsWrapper>
                        <ButtonWrapper>
                            <Timer
                                onDurationChange={requestedValue => breakouts.setDuration(requestedValue)}
                                startTime={breakouts.startTime}
                                duration={breakouts.duration}
                                id='bor-timer'
                                max={50}
                                min={2}
                            />
                        </ButtonWrapper>
                        <ButtonWrapper>
                            <SettingsIconButton id='bor-settings-button' active={viewBorModal} onClick={showEditBorModal} />
                        </ButtonWrapper>
                    </BreakoutButtonsWrapper>
                ) : (
                    <></>
                )}
            </LeftWrapper>
            {breakouts?.isActive ? (
                <CenterWrapper>
                    <OverseeButton
                        id='bor-oversee-button'
                        color='secondary'
                        disabled={breakouts.teacherRoom === null}
                        onClick={() => breakouts.linkTeacher(null)}
                    >Oversee</OverseeButton>
                </CenterWrapper>
            ) : null}
            <RightWrapper>
                <ButtonWrapper>
                    <SettingsMenu setCurrentSidebar={() => {}} />
                </ButtonWrapper>
                <ButtonWrapper>
                    <EndCallButton text='End Class' disabled={!sessionJoined || breakouts?.isActive} onClick={toggleLeaveDialog} />
                </ButtonWrapper>
            </RightWrapper>
        </HeaderWrapper>
    )
}

export { TopControls }
