export const ExpelDialogContent = {
    title: 'Expel Student',
    dsescription: 'Are you sure you want to expel the following student from the class?',
    buttonName: 'Expel Student'
}

export const ExpelStudentScreenContent = {
    title: 'You have been removed from the class',
    description: 'You will not be able to rejoin this session',
    buttonName: 'Go to dashboard'
}

export const ButtonTexts = {
    CCSuggestionOutlined: 'Share Cue Cards in speak mode',
    CCSuggestionPrimary: 'Setup Breakout Rooms',
    StartBR: 'Start Breakout Rooms',
    Next: 'Next',
    Previous: 'Previous',
    ClearSelection: 'Clear all selection',
    ShareCC: 'Share Cue Cards',
    ApplyChanges: 'Apply Changes',
    RemoveCC: 'Remove Cue Cards',
    GoBack: 'Go Back',
    ExitBR: 'Exit Breakout Rooms',
    SaveChanges: 'Save Changes'
}

export const DialogContent = {
    CCSuggestion: {
        title: 'Share Cue Cards in Breakout Rooms',
        body: 'Since there are more than three students in the class, it is recommended to share the Cue Card in Breakout Rooms.'
    },
    BreakoutRoom: {
        stepOneTitle: 'Breakout Rooms',
        stepTwoTitle: 'Share Cue Cards in Breakout Rooms'
    },
    CueCards: {
        title: 'Share Cue Cards'
    },
    RemoveCC: {
        title: 'Remove Cue Cards',
        body: 'Moving to another section will remove the cue cards for all students. Do you want to remove cue cards and continue?'
    },
    ExitBR: {
        title: 'Exit Breakout Rooms',
        body: 'You are about to end the breakout rooms and return to class mode. Do you want to proceed and end breakout rooms for all students?'
    }
}
