import { ReactSortable } from 'react-sortablejs'

import { DragIndicator } from 'icons'

import { getDisplayName, getSameOriginPhotoUrl } from 'utils/get-display-name'

import { RoomAssignment } from './BreakoutRoomsModal'

import { Avatar, EmptyContent, RoomModule, RoomTitle, Student } from './style'

type SettingsStepProps = {
    roomsCount: number
    getRoom: (index: number) => RoomAssignment[]
    setRooms: (index: number, students: RoomAssignment[]) => void
    roomsEmpty: boolean
    roomsFull: boolean
}

const RoomsModalStep: React.FC<SettingsStepProps> = ({ roomsCount, getRoom, setRooms, roomsEmpty, roomsFull }) => {
    return (
        <div id='bor-modal-rooms-wrapper'>
            {Array.from(Array(roomsCount)).map((_, i) => (
                <RoomModule id='bor-modal-room-module' key={`Room ${String.fromCharCode('A'.charCodeAt(0) + i)}`}>
                    <RoomTitle isRoomEmpty={getRoom(i).length === 0}>
                        {`Room ${String.fromCharCode('A'.charCodeAt(0) + i)}`}
                        {getRoom(i).length > 4 ? (
                            <span>Room cannot have more than 4 students</span>
                        ) : null}
                    </RoomTitle>
                    <div>
                        <ReactSortable
                            id='bor-modal-rooms-container'
                            list={getRoom(i)}
                            setList={(data: RoomAssignment[]) => setRooms(i, data)}
                            animation={200}
                            group='shared'
                            handle='.dragHandle'
                        >
                            {getRoom(i).length === 0
                                ? <EmptyContent>
                                    {`You can leave this room empty and it won't be displayed to students;
                                    feel free to continue with your setup, and assign participants later if needed.`}
                                </EmptyContent>
                                : getRoom(i).map((s: RoomAssignment) => (
                                    <Student id='bor-modal-student-drag' key={s.id}>
                                        <img src={DragIndicator} className='dragHandle' />
                                        <Avatar id='bor-modal-avatar' sx={{
                                            backgroundImage: s.wseProfilePhotoUrl
                                                ? `url('${getSameOriginPhotoUrl(s.wseProfilePhotoUrl)}')` : 'none'
                                        }}>
                                            {!s.wseProfilePhotoUrl ? (
                                                <span>{getDisplayName(s, 'initials')}</span>
                                            ) : null}
                                        </Avatar>
                                        <span>{getDisplayName(s, 'student-name')}</span>
                                    </Student>
                                ))
                            }
                        </ReactSortable>
                    </div>
                </RoomModule>
            ))}
        </div>
    )
}

export { RoomsModalStep }
