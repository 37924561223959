import { useContext, useMemo, useState } from 'react'

import { Divider, ListSubheader } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { CueCardInfo } from 'api/classroom/ClassroomState'
import { CueCardItemArray, ParticipantData } from './type'

import { ConferenceContext } from 'contexts/conference'
import { BreakoutRoomsState } from 'pages/useBreakouts'

import { getDisplayName } from 'utils/get-display-name'
import { numberToAlphabet } from '../breakout-rooms/breakout.service'

import { ViewCueCardImage } from '../ViewCueCardsModal'

import ZoomIcon from '../../../../../pages/teacher/classroom/components/ZoomIcon.svg'

import {
    SelectContainer,
    Avatar,
    StudentItem,
    SelectTextField,
    CustomMenuItem,
    StudentSelectContainer,
    SlideItem,
    SelectFormControl,
    IconWrapperTile,
    IconWrapper
} from './style'

const CueCardSelect: React.FC<{
    studentNameList: ParticipantData[]
    handleChange: (activityInfo: [CueCardInfo, number], participant: ParticipantData) => void
    selectedCueCards: CueCardItemArray
    cueCardResponse: false | CueCardInfo[] | null
    breakoutActive: boolean
    selectedActivity?: string
    breakout?: BreakoutRoomsState
    index?: number
    setZoomedCueCardImage?: React.Dispatch<React.SetStateAction<ViewCueCardImage>>
}> = ({
    studentNameList,
    breakout,
    handleChange,
    selectedCueCards,
    cueCardResponse,
    setZoomedCueCardImage,
    breakoutActive,
    selectedActivity,
    index = 0
}) => {
    const conference = useContext(ConferenceContext)

    const [selectedStudentId, setSelectedStudentId] = useState<string>('')
    const [hoveredIndex, setHoveredInmdex] = useState<number | null>(null)

    const checkMediaAvailability = (contentId: string): boolean => {
        const activeRoomUsers = breakout?.rooms?.find(br => br.users.includes(selectedStudentId))?.users
        const activeRoomSelectedCueCards = selectedCueCards.filter(data => activeRoomUsers?.includes(data.id)).map(cc => cc.cueCard?.contentId)
        return !activeRoomSelectedCueCards.includes(contentId)
    }

    const handleOnClick = (par: ParticipantData): any => setSelectedStudentId(par.id)

    const getParticipantDP = (id: string): string => conference.participants.find(data => data.wseUserId === id)?.wseProfilePhotoUrl || ''

    const getCueCardData = (participantId: string): ViewCueCardImage => {
        const cueCardImage = selectedCueCards ? selectedCueCards.find(data => data.id === participantId) : null
        return { url: cueCardImage?.cueCard?.imageURL ?? '', name: `${cueCardImage?.activityNumber} Student ${cueCardImage?.indexValue}` }
    }

    const fetchSelectedValue = (par: ParticipantData): any => {
        const selectedStudent = selectedCueCards.find(data => (data.id === par.id && data.cueCard))
        if ((selectedCueCards && selectedCueCards.length === 0) || (selectedCueCards.length > 0 && !selectedStudent)) {
            return 'Select a Cue Card'
        } else {
            return `${selectedStudent?.activityNumber} Student ${selectedStudent?.indexValue}`
        }
    }

    const generateActivityList = useMemo(
        () => (participant: ParticipantData, filterMode: boolean): JSX.Element[][] => {
            if (!cueCardResponse) return []

            return cueCardResponse.map(activities => {
                const activityItems = activities.activityMedia.map((data, mediaIndex) => {
                    const isVisible =
                        (!breakoutActive || checkMediaAvailability(data.contentId)) &&
                        (selectedActivity === 'All Activity' ? true : activities.activityNumber === selectedActivity?.replace('Activity ', ''))

                    return (
                        <CustomMenuItem
                            data-testid={`activity-${activities.activityNumber}-media-${mediaIndex}`}
                            key={`activity-${activities.activityNumber}-media-${mediaIndex}`}
                            onClick={() => handleChange([activities, mediaIndex], participant)}
                            value={`${activities?.activityNumber} Student ${numberToAlphabet(mediaIndex + 1)}`}
                            sx={{
                                visibility: isVisible ? 'visible' : 'hidden',
                                display: isVisible ? 'block' : 'none'
                            }}
                        >
                            {`${activities.activityNumber} Student ${numberToAlphabet(mediaIndex + 1)}`}
                        </CustomMenuItem>
                    )
                })

                return filterMode
                    ? activityItems
                    : [
                        <ListSubheader
                            id='category'
                            key={`activity-${activities.activityNumber}`}
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '16px',
                                lineHeight: '38px',
                                padding: '8px'
                            }}
                        >
                            Activity {activities.activityNumber}
                        </ListSubheader>,
                        ...activityItems
                    ]
            })
        },
        [cueCardResponse, selectedActivity, JSON.stringify(selectedCueCards), breakoutActive, handleChange]
    )

    const renderActivityList = useMemo(() => {
        if (selectedActivity === 'All Activity') {
            return (participant: ParticipantData) => generateActivityList(participant, false)
        }
        return (participant: ParticipantData) => generateActivityList(participant, true)
    }, [selectedActivity, generateActivityList])

    return (
        <>
            <SelectContainer key={`select-container-${index}`} breakoutActive={breakoutActive}>
                {studentNameList.map((participant, participantIndex) => (
                    <>
                        <SelectFormControl
                            key={participantIndex}
                            breakoutActive={breakoutActive}
                        >
                            <StudentItem breakoutActive={breakoutActive}>
                                {breakoutActive ? <Avatar id='bor-modal-avatar' sx={{
                                    backgroundImage: getParticipantDP(participant.id)
                                        ? `url('${getParticipantDP(participant.id)}')` : 'none'
                                }}>
                                    {!getParticipantDP(participant.id) ? (
                                        <span style={{
                                            marginLeft: 0,
                                            fontSize: '10px'
                                        }}>{getDisplayName({ firstName: participant.firstName, lastName: participant.lastName }, 'initials')}</span>
                                    ) : null}
                                </Avatar> : null}
                                {participant.firstName + ` ${participant.lastName ? participant.lastName : ''}` }
                            </StudentItem>
                            <StudentSelectContainer breakoutActive={breakoutActive}>
                                <SelectTextField
                                    defaultValue={fetchSelectedValue(participant)}
                                    label=''
                                    margin='none'
                                    maxRows={4}
                                    minRows={1}
                                    name='textfield'
                                    id={`button-${participant.id}`}
                                    breakoutActive={breakoutActive}
                                    onChange={() => setSelectedStudentId('')}
                                    onClick={() => handleOnClick(participant)}
                                    value={fetchSelectedValue(participant)}
                                    placeholder='Select a Cue Card'
                                    data-testid='select-cc'
                                    rows={1}
                                    select
                                    size='medium'
                                    type='text'
                                    variant='outlined'
                                    SelectProps={{
                                        IconComponent: ExpandMoreIcon,
                                        MenuProps: {
                                            PaperProps: {
                                                sx: theme => ({
                                                    borderRadius: '0px 0px 8px 8px',
                                                    maxHeight: '192px',
                                                    width: breakoutActive ? '256px' : '424px',
                                                    border: '1px solid rgba(0, 0, 0, 0.14)',
                                                    boxShadow: '0px 7px 20px 0px rgba(0, 0, 0, 0.06), 0px 3px 1px 0px rgba(0, 0, 0, 0.02)',
                                                    padding: '0px 8px 8px 8px',
                                                    '& .MuiMenu-list': {
                                                        paddingTop: theme.spacing(2)
                                                    },
                                                    '.MuiMenuItem-root': {
                                                        backgroundColor: 'transparent',
                                                        '&:hover': {
                                                            backgroundColor: '#71809629',
                                                            cursor: 'pointer'
                                                        }
                                                    }
                                                })
                                            }
                                        }
                                    }}
                                >
                                    <CustomMenuItem
                                        data-testid='activity-select-cue-card'
                                        key='activity-select-cue-card'
                                        sx={{
                                            visibility: 'hidden',
                                            display: 'none',
                                            color: '#A0AEC0'
                                        }}
                                        value='Select a Cue Card'>
                                        Select a Cue Card
                                    </CustomMenuItem>
                                    {renderActivityList(participant)}
                                </SelectTextField>

                                {studentNameList[participantIndex].isSelected ? (
                                    <IconWrapperTile onMouseEnter={() => setHoveredInmdex(participantIndex)} onMouseLeave={() => setHoveredInmdex(null)}>
                                        <SlideItem
                                            data-testid='cueCardImage'
                                            breakoutActive={breakoutActive}
                                            src={selectedCueCards.length > 0 ? getCueCardData(participant.id).url : ''}
                                            alt='cue card image'
                                        />
                                        {hoveredIndex === participantIndex
                                            ? <IconWrapper
                                                src={ZoomIcon}
                                                alt='Zoom Icon'
                                                onClick={() => {
                                                    if (setZoomedCueCardImage) {
                                                        setZoomedCueCardImage(getCueCardData(participant.id))
                                                    }
                                                }} />
                                            : null}
                                    </IconWrapperTile>
                                ) : (
                                    <SlideItem
                                        data-testid='cue-card-thumbnail-grey'
                                        breakoutActive={breakoutActive}
                                        sx={{ backgroundColor: '#CBD5E0', borderRadius: '8px' }}
                                    />
                                )}
                            </StudentSelectContainer>
                        </SelectFormControl>
                        {breakoutActive && participantIndex !== (studentNameList.length - 1) ? <Divider sx= {{ backgroundColor: '1px solid grey' }} /> : null}
                    </>
                ))}
            </SelectContainer>
        </>
    )
}

export { CueCardSelect }
