import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { Action, Icon } from 'hello-design-system'
import { fetchButtonData } from './breakout-rooms/breakout.service'
import { ButtonTexts, DialogContent } from 'utils/constants'
import { CCSuggestDialogComponent } from './dialog'

export const StyledBox = styled(Box)({
    fontWeight: 'bold'
})

export const StyledTypography = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    color: '#2D3748 !important',
    margin: '0 !important'
}))

const { CCSuggestionOutlined, CCSuggestionPrimary } = ButtonTexts

const ShowCueCardBRSuggestion: React.FC<{ close: () => void; continueCC: () => void; startBRSetup: () => void }> = ({ close, continueCC, startBRSetup }) => {

    const actions: Action[] = [
        fetchButtonData(CCSuggestionOutlined, 'outlined', continueCC),
        fetchButtonData(CCSuggestionPrimary, 'contained', startBRSetup)
    ]

    return (
        <>
            <CCSuggestDialogComponent
                open
                onClose={close}
                title={DialogContent.CCSuggestion.title}
                actions={actions}
                icon={<Icon color='inherit' iconName='InfoRounded' />}
                maxWidth={'sm'}
                showCloseIcon
            >
                <StyledBox>
                    <StyledTypography
                        color='text.primary'
                        gutterBottom
                    >
                        {DialogContent.CCSuggestion.body}
                    </StyledTypography>
                </StyledBox>
            </CCSuggestDialogComponent>
        </>
    )
}

export { ShowCueCardBRSuggestion }
