import { useContext, useEffect } from 'react'
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'

import { BookingContext } from 'contexts/booking'

// Create a GrowthBook instance
const gb = new GrowthBook({
    attributes: { role: 'student' },
    apiHost: process.env.REACT_APP_GROWTH_BOOK_API_HOST,
    clientKey: process.env.REACT_APP_GROWTH_BOOK_CLIENT_KEY,
    // Enable easier debugging during development
    enableDevMode: (process.env.ENV_NAME === 'qualrow'),
    // Disable background streaming connection
    backgroundSync: false,
    subscribeToChanges: false,
    // Only required for A/B testing
    // Called every time a user is put into an experiment
    trackingCallback: (
        experiment: { key: string },
        result: { key: string; variationId: number }
    ) => {
        // Note: no need to install or implement GB lib or this callback in Angular, window.react.gb is the same instance on all pages
        const gbAttributes = gb.getAttributes()
        if (gbAttributes && Object.keys(gbAttributes).length) {
            // https://docs.growthbook.io/guide/GA4-google-analytics
            // This part is responsibility of WSE since Google Analytics and Growth Book are managed by business and exact labels are their for they to decide
            if ('dataLayer' in window) {
                window.dataLayer.push({ event: 'ab_test', user_id: gbAttributes.ssdsId, experiment_id: experiment.key, variation_id: `${result.variationId}` })
            }
        }
    }
})

window.growthbook = gb

export const GBProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
    const booking = useContext(BookingContext)
    // const user = useTypedSelector((s) => s.domain.localStorage.user);
    // const preferredLanguage = useTypedSelector((s) => s.domain.localStorage.preferredLanguage)

    useEffect(() => {
        if (booking.status === 'validated') {
            gb.setAttributes({
                id: booking.user.id,
                userId: booking.user.id, // duplicate of "id" in case GB dashboard or GA dashboard refers to it as "userId"
                role: booking.user.role,
                ssdsId: booking.user.id,
                refreshToken: booking.user.accessToken
            })
        }
    }, [booking])

    useEffect(() => {
        const loadFeatures = async (): Promise<void> => {
            await gb.loadFeatures()
        }
        void loadFeatures()
    }, [])

    return (
        <GrowthBookProvider growthbook={gb}>{children}</GrowthBookProvider>
    )
}
