import { CueCardItemArray, ParticipantData } from './type'

import { CueCardInfo } from 'api/classroom/ClassroomState'
import { BreakoutRoomsState } from 'pages/useBreakouts'

import { RoomTitle, Student } from './style'
import { CueCardSelect } from './CueCardSelect'
import { RoomModule } from '../breakout-rooms/style'
import { ViewCueCardImage } from '../ViewCueCardsModal'

const CueCardBRModal: React.FC<{
    breakouts: BreakoutRoomsState
    studentNameList: ParticipantData[]
    handleChange: (activityInfo: [CueCardInfo, number], participant: ParticipantData) => void
    selectedCueCards: CueCardItemArray
    cueCardResponse: false | CueCardInfo[] | null
    selectedActivity?: string
    setZoomedCueCardImage?: React.Dispatch<React.SetStateAction<ViewCueCardImage>>
}> = ({ breakouts, studentNameList, selectedActivity, handleChange, selectedCueCards, cueCardResponse, setZoomedCueCardImage }) => {
    return (
        <div id='bor-modal-rooms-wrapper'>
            {breakouts?.rooms?.map((data, i) => {
                if (data.users.length) {
                    const roomStudents = studentNameList.filter(user => data.users.includes(user.id))
                    return (
                        <RoomModule id='bor-modal-room-module' key={`Room ${String.fromCharCode('A'.charCodeAt(0) + i)}`}>
                            <RoomTitle>
                                {`Room ${String.fromCharCode('A'.charCodeAt(0) + i)}`}
                            </RoomTitle>
                            <div>
                                <Student id='bor-modal-student-drag'>
                                    <CueCardSelect
                                        studentNameList={roomStudents}
                                        handleChange={handleChange}
                                        selectedCueCards={selectedCueCards}
                                        cueCardResponse={cueCardResponse}
                                        setZoomedCueCardImage={setZoomedCueCardImage}
                                        breakout={breakouts}
                                        selectedActivity={selectedActivity}
                                        breakoutActive
                                        index={i}
                                    />
                                </Student>
                            </div>
                        </RoomModule>
                    )
                } else {
                    return null
                }
            })}
        </div>
    )
}

export { CueCardBRModal }
