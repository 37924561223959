import { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { Action } from 'hello-design-system'

import { ConferenceContext } from 'contexts/conference'
import { BreakoutRoomsState } from 'pages/useBreakouts'

import { fetchButtonData } from './breakout-rooms/breakout.service'

import { ButtonTexts, DialogContent } from 'utils/constants'

import { CCBRExitDialogComponent } from './dialog'

export const StyledBox = styled(Box)({
    display: 'inline',
    fontWeight: 'bold'
})

export const StyledTypography = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    color: '#2D3748 !important'
}))

const { GoBack, ExitBR } = ButtonTexts

const ExitBORModal: React.FC<{ close: () => void; breakouts?: BreakoutRoomsState }> = ({ close, breakouts }) => {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const conference = useContext(ConferenceContext)

    const cueCardForStudent = conference.status === 'session-joined' ? conference.cueCard : []
    const exitBR = (): void => {
        if (conference.status === 'session-joined' && cueCardForStudent) {
            const updatedCueCards = cueCardForStudent.map(data => {
                return {
                    id: data.id,
                    cueCard: null
                }
            })
            conference.setCueCardForStudent(updatedCueCards)
            conference.primus.send('cueCard:add', {
                cueCards: updatedCueCards
            })
        }
        breakouts?.stop()
        pathname.includes('/teacher/classroom/cue-cards') && navigate('/teacher/classroom')
        close()
    }

    const actions: Action[] = [
        fetchButtonData(GoBack, 'outlined', close),
        fetchButtonData(ExitBR, 'contained', exitBR)
    ]

    return (
        <>
            <CCBRExitDialogComponent
                open={true}
                onClose={() => close()}
                title={DialogContent.ExitBR.title}
                actions={actions}
                maxWidth={'sm'}
                showCloseIcon={true}
                fullScreen={false}
            >
                <StyledBox>
                    <StyledTypography
                        color='text.primary'
                        gutterBottom
                    >
                        {DialogContent.ExitBR.body}
                    </StyledTypography>
                </StyledBox>
            </CCBRExitDialogComponent>
        </>
    )
}

export { ExitBORModal }
