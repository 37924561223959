import { useContext, useRef } from 'react'
import { styled } from '@mui/material'
import { ConferenceContext } from 'contexts/conference'
import { ConferenceParticipant } from 'contexts/conference/types'
import { WseUserId } from 'api/types'
import { StandardButton } from 'components/buttons'
import { BreakoutRoomsState } from 'pages/useBreakouts'
import { useGridLayout } from './useGridLayout'
import { ParticipantTile } from '../'
import { SpeakingIndicator } from 'components/speaker-indicator/SpeakerIndicator'
import { ViewCueCardImage } from 'pages/teacher/classroom/components/ViewCueCardsModal'

const Wrapper = styled('div')(({ theme }) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2)
}))
const RoomWrapper = styled('div')<{ roomsCount: number; active: boolean }>(({ theme, roomsCount, active }) => ({
    width: `calc(100% / ${roomsCount})`,
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    willChange: 'border-color',
    transition: 'border-color 0.3s ease-out',
    border: `solid ${theme.spacing(0.5)} ${active ? theme.palette.primary.darker : theme.palette.neutral.darker}`,
    borderRadius: theme.spacing(2),
    overflow: 'hidden'
}))
const RoomHeader = styled('div')<{ active: boolean }>(({ theme, active }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: theme.spacing(6),
    padding: theme.spacing(2),
    willChange: 'background-color',
    transition: 'background-color 0.3s ease-out',
    backgroundColor: active ? theme.palette.primary.darker : theme.palette.neutral.darker,
    '> div': {
        display: 'flex',
        alignItems: 'center'
    },
    p: {
        fontWeight: '800',
        willChange: 'color',
        transition: 'color 0.3s ease-out',
        color: active ? theme.palette.neutral.main : theme.palette.secondary.darker
    },
    button: {
        padding: `${theme.spacing(0.25)} ${theme.spacing(0.5)}`,
        minWidth: 'auto',
        border: `solid 1px ${theme.palette.neutral.hover}`,
        '&:hover': {
            border: `solid 1px ${theme.palette.neutral.hover}`
        }
    }
}))

const ParticipantsWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    height: `calc(100% - ${theme.spacing(6)})`,
    padding: theme.spacing(2),
    '> div': {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center'
    }
}))
const TileWrapper = styled('div')(({ theme }) => ({
    width: 'var(--tile-width)',
    height: 'var(--tile-height)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2px'
}))

const BorZoomLayout: React.FC<{
    breakouts: BreakoutRoomsState
    showCueCardSharingModal?: () => void
    cueCardZoomModal?: (data: ViewCueCardImage) => void
    isCueCard?: boolean
}> = ({ breakouts, showCueCardSharingModal, cueCardZoomModal, isCueCard = false }) => {
    const conference = useContext(ConferenceContext)
    if (conference.status !== 'session-joined') {
        return null
    }

    const roomARef = useRef<HTMLDivElement | null>(null)
    const roomBRef = useRef<HTMLDivElement | null>(null)
    const roomCRef = useRef<HTMLDivElement | null>(null)
    const roomDRef = useRef<HTMLDivElement | null>(null)
    const refs = [roomARef, roomBRef, roomCRef, roomDRef]
    const rooms = breakouts.rooms
    rooms.forEach(r => {
        const ref = refs[r.id]
        if (ref) {
            useGridLayout(ref, r.users.length)
        }
    })

    const participantData: Map<WseUserId, ConferenceParticipant> = new Map()
    conference.participants.forEach(p => {
        participantData.set(p.wseUserId, p)
    })
    rooms.forEach(r => {
        r.users.forEach(u => {
            if (participantData.has(u)) {
                if (breakouts.teacherRoom === r.id) {
                    void conference.rtcSession.unmuteLocally(participantData.get(u)!)
                } else {
                    void conference.rtcSession.muteLocally(participantData.get(u)!)
                }
            }
        })
    })

    const getActiveRoomsCount = (): number => rooms.reduce((count, room) => room.users.length > 0 ? count + 1 : count, 0)
    const speakers = [...conference.speakers].map(s => conference.participants.find(p => p.clientUserId === s)).map(s => s?.wseUserId)

    return (
        <Wrapper id='participants-grid'>
            {rooms.filter(room => room.users.length > 0).map(r => (
                <RoomWrapper id='bor-room-wrapper' key={`Room-${r.id}`} roomsCount={getActiveRoomsCount()} active={breakouts.teacherRoom === r.id}>
                    <RoomHeader id='bor-room-header' active={breakouts.teacherRoom === r.id}>
                        <div>
                            <p>{`Room ${String.fromCharCode('A'.charCodeAt(0) + r.id)}`}</p>
                            {breakouts.teacherRoom !== r.id && r.users.some(u => speakers.includes(u)) ? (
                                <SpeakingIndicator />
                            ) : null}
                        </div>
                        {breakouts.teacherRoom !== r.id ? (
                            <StandardButton id='bor-join-button' text='Join' color='secondary' onClick={() => breakouts.linkTeacher(r.id)} />
                        ) : null}
                    </RoomHeader>
                    {conference.status === 'session-joined' ? (
                        <ParticipantsWrapper id='bor-room-participants-wrapper'>
                            <div ref={refs[r.id]}>
                                {r.users.map((u: WseUserId, i: number) => {
                                    return participantData.has(u) ? (
                                        <TileWrapper key={u}>
                                            <ParticipantTile
                                                id='participant-tile'
                                                participant={participantData.get(u)!}
                                                breakouts={breakouts}
                                                isCueCards={isCueCard}
                                                isTeacher
                                                forceTileBorders
                                                cueCardZoomModal={cueCardZoomModal}
                                                showCueCardSharingModal={showCueCardSharingModal}
                                            />
                                        </TileWrapper>
                                    ) : null
                                })}
                            </div>
                        </ParticipantsWrapper>
                    ) : null}
                </RoomWrapper>
            ))}
        </Wrapper>
    )
}

export { BorZoomLayout }
