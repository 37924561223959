import { Dispatch, SetStateAction, useContext } from 'react'
import { styled, Button } from '@mui/material'
import { ConferenceContext } from 'contexts/conference'
import { SettingsMenu } from 'components/SettingsMenu'
import { ButtonWrapper, EndCallButton } from 'components/buttons'
import { BreakoutRoomsState } from 'pages/useBreakouts'
import { SidebarProps } from 'components/sidebar'

const HeaderWrapper = styled('div')(({ theme }) => ({
    boxSizing: 'content-box',
    display: 'flex',
    padding: '0 var(--page-side-margin)',
    gap: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        padding: 'var(--page-side-margin)'
    }
}))
const LeftWrapper = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.neutral.main,
    padding: 'var(--page-v-margin) 0',
    width: '50%',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'start',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: 0,
        justifyContent: 'center'
    }
}))
const RightWrapper = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.neutral.main,
    padding: 'var(--page-v-margin) 0',
    flexGrow: 1,
    flexShrink: 1,
    width: '50%',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'end',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: 0,
        justifyContent: 'center'
    }
}))
const StyledButton = styled(Button)(({ theme }) => ({
    pointerEvents: 'none',
    p: {
        fontWeight: 700
    },
    div: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: theme.spacing(2),
        padding: `${theme.spacing(0.25)} ${theme.spacing(1)}`,
        backgroundColor: theme.palette.neutral.main,
        color: theme.palette.secondary.main,
        borderRadius: theme.spacing(3)
    }
}))

type TopControlsProps = {
    breakouts?: BreakoutRoomsState
    toggleLeaveDialog?: () => void
    currentSidebar: SidebarProps
    setCurrentSidebar: Dispatch<SetStateAction<SidebarProps>>
}

const TopControls: React.FC<TopControlsProps> = ({ breakouts, toggleLeaveDialog, currentSidebar, setCurrentSidebar }) => {
    const conference = useContext(ConferenceContext)
    const sessionJoined = conference.status === 'session-joined'
    const currentRoom = sessionJoined && breakouts?.isActive ? breakouts?.getCurrentRoom() : null
    const roomTitle = currentRoom || currentRoom === 0 ? `You are in Breakout Room ${String.fromCharCode('A'.charCodeAt(0) + currentRoom)}` : null

    return (
        <HeaderWrapper>
            {roomTitle ? (
                <LeftWrapper>
                    <ButtonWrapper>
                        <StyledButton id='bor-student-button' color='primary' variant='contained'>
                            <p>{roomTitle}</p>
                            {breakouts?.teacherRoom !== currentRoom ? (
                                <div>Teacher is in another room</div>
                            ) : null}
                        </StyledButton>
                    </ButtonWrapper>
                </LeftWrapper>
            ) : null}
            <RightWrapper>
                <ButtonWrapper>
                    <SettingsMenu currentSidebar={currentSidebar} setCurrentSidebar={setCurrentSidebar} isStudent={true} />
                </ButtonWrapper>
                <ButtonWrapper>
                    <EndCallButton text='Leave' disabled={!sessionJoined} onClick={toggleLeaveDialog} />
                </ButtonWrapper>
            </RightWrapper>
        </HeaderWrapper>
    )
}

export { TopControls }
