import { styled } from '@mui/material'

const RoomModule = styled('div')<{ isCC?: boolean }>(({ theme, isCC = false }) => ({
    borderRadius: theme.spacing(1),
    border: `solid 1px ${theme.palette.neutral?.hover}`,
    overflow: 'hidden',
    margin: isCC ? `${theme.spacing(2)} 0` : `0 0 ${theme.spacing(1.5)} 0`,
    p: {
        width: '100%',
        backgroundColor: theme.palette.neutral?.darker,
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        fontWeight: '600',
        span: {
            color: theme.palette.error?.main,
            fontSize: theme.spacing(1.75),
            fontWeight: 400,
            marginLeft: theme.spacing(2)
        }
    },
    '> div': {
        padding: theme.spacing(2)
    }
}))

const Student = styled('div')(({ theme }) => ({
    border: '1px solid #71809629 !important',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    img: {
        cursor: 'grab'
    },
    '> span': {
        fontWeight: '600'
    },
    '&:last-of-type': {
        margin: 0
    }
}))

const EmptyContent = styled('p')(() => ({
    backgroundColor: 'transparent !important',
    fontSize: '14px !important',
    fontWeight: '400 !important',
    color: '#4A5568 !important',
    padding: '0 !important'
}))

const Avatar = styled('div')(({ theme }) => ({
    fontSize: theme.spacing(1.5),
    lineHeight: theme.spacing(1.5),
    fontWeight: 800,
    width: theme.spacing(3),
    aspectRatio: '1',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.35)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: theme.palette.neutral?.main,
    margin: `0 ${theme.spacing(1)}`
}))

const RoomTitle = styled('p')<{ isRoomEmpty: boolean }>(({ isRoomEmpty }) => ({
    backgroundColor: `${isRoomEmpty ? '#F7FAFC' : '#EDF2F7'} !important`,
    color: `${isRoomEmpty ? '#718096' : '#2D3748'} !important`
}))

export { RoomModule, Student, EmptyContent, Avatar, RoomTitle }
