import { Box, FormControl, MenuItem, styled, TextField } from '@mui/material'

const RoomTitle = styled('p')(() => ({
    backgroundColor: '#EDF2F7 !important'
}))

const Student = styled(Box)(({ theme }) => ({
    backgroundColor: 'transparent',
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    img: {
        cursor: 'grab'
    },
    '> span': {
        fontWeight: '600'
    },
    '&:last-of-type': {
        margin: 0
    }
}))
const SelectContainer = styled(Box)<{ breakoutActive: boolean }>(({ breakoutActive, theme }) => ({
    maxHeight: theme.spacing(57.5),
    overflow: 'auto',
    width: theme.spacing(66.25),
    '> div': {
        margin: breakoutActive ? `${theme.spacing(2)} 0 !important` : `${theme.spacing(1)} 0 0 0 !important`,
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        '&:last-child': {
            margin: `${theme.spacing(2)} 0 ${theme.spacing(1)} 0 !important`
        },
        '&:first-of-type': {
            margin: breakoutActive ? `0 0 ${theme.spacing(2)} 0 !important` : '0'
        }
    }
}))

const Avatar = styled(Box)(({ theme }) => ({
    fontSize: theme.spacing(1.5),
    lineHeight: theme.spacing(1.5),
    fontWeight: 800,
    width: theme.spacing(3),
    height: theme.spacing(3),
    aspectRatio: '1',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.neutral?.dark,
    opacity: 0.35,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: theme.palette.neutral?.main,
    margin: `0 ${theme.spacing(1)}`
}))

const StudentItem = styled(Box)<{ breakoutActive: boolean }>(({ theme, breakoutActive }) => ({
    textAlign: 'left',
    width: breakoutActive ? `${theme.spacing(18.75)} !important` : `${theme.spacing(53)} !important`,
    fontSize: theme.spacing(2),
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent !important'
}))

const SelectTextField = styled(TextField)<{ breakoutActive: boolean }>(({ breakoutActive, value, theme }) => ({
    '.MuiOutlinedInput-root': {
        borderRadius: `${theme.spacing(1)} !important`,
        width: breakoutActive ? theme.spacing(32) : theme.spacing(53),
        height: breakoutActive ? theme.spacing(5) : theme.spacing(6.75),
        padding: `${theme.spacing(1.25)} ${theme.spacing(1.5)}`,
        border: '2px solid rgba(0, 0, 0, 0.14)',
        margin: '0',
        '.MuiSelect-select': {
            padding: '0 !important'
        },
        '.MuiInputBase-input': {
            color: value === 'Select a Cue Card' ? '#A0AEC0' : '#2D3748'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderStyle: 'none !important'
        }
    },
    '.MuiTextField-root': {
        cursor: 'pointer'
    }
}))

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
    width: theme.spacing(46.5),
    margin: '0px',
    padding: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(3)}`,
    alignItems: 'center',
    fontSize: theme.spacing(2),
    fontWeight: 'normal',
    fontFamily: 'Noto Sans',
    lineHeight: 'unset !important',
    '&:hover': {
        width: '100%'
    }
}))

const StudentSelectContainer = styled(Box)<{ breakoutActive: boolean }>(({ breakoutActive, theme }) => ({
    width: breakoutActive ? `${theme.spacing(43.25)} !important` : 'unset',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(breakoutActive ? 0 : 3),
    marginTop: theme.spacing(breakoutActive ? 0 : 1)
}))

const SlideItem = styled('img')<{ breakoutActive: boolean }>(({ breakoutActive, theme }) => ({
    width: breakoutActive ? theme.spacing(8.25) : theme.spacing(10.75),
    height: breakoutActive ? theme.spacing(5) : theme.spacing(6.75),
    cursor: breakoutActive ? 'pointer !important' : 'default !important',
    border: 'solid 1px rgba(0, 0, 0, 0.06)'
}))

const SelectFormControl = styled(FormControl)<{ breakoutActive: boolean }>(({ breakoutActive }) => ({
    display: 'flex',
    position: 'relative',
    flexDirection: breakoutActive ? 'row' : 'column',
    width: breakoutActive ? '520px' : '519px',
    margin: '0px 0px 12px 0px'
}))

const ActivityFilterSelectTextField = styled(TextField)(({ theme }) => ({
    '.MuiOutlinedInput-root': {
        borderRadius: `${theme.spacing(1)} !important`,
        width: theme.spacing(25),
        height: theme.spacing(5),
        padding: `${theme.spacing(1.25)} ${theme.spacing(1.5)}`,
        border: '2px solid rgba(0, 0, 0, 0.14)',
        margin: '0px',
        '.MuiSelect-select': {
            padding: '0px !important'
        },
        '.MuiInputBase-input': {
            color: '#2D3748',
            display: 'flex',
            justifyContent: 'space-between'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderStyle: 'none !important'
        }
    },
    '.MuiSelect-outlined': {
        borderRadius: '0',
        img: {
            visibility: 'hidden'
        }
    },
    '.MuiTextField-root': {
        cursor: 'pointer'
    }
}))

const ActivityFilterMenuItem = styled(MenuItem)<{ isSelected: boolean }>(({ isSelected, theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0px',
    alignItems: 'center',
    fontSize: theme.spacing(1.75),
    fontWeight: isSelected ? '600' : 'normal',
    fontFamily: 'Noto Sans',
    lineHeight: 'unset !important',
    '& .Mui-selected': {
        backgroundColor: 'transparent'
    },
    '&:hover': {
        width: '100%'
    }
}))

const SelectActivityContainer = styled(Box)<{ isBreakout?: boolean }>(({ isBreakout = false, theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: isBreakout ? theme.spacing(3.5) : theme.spacing(2)
}))

const SelecteActivityTitle = styled(Box)(({ theme }) => ({
    h2: {
        fontSize: theme.spacing(2),
        fontWeight: '600'
    },
    p: {
        fontSize: theme.spacing(1.5),
        fontWeight: '400'
    }
}))

const Tick = styled('img')(() => ({
    width: '20px',
    height: '20px',
    color: '#718096'
}))

const IconWrapperTile = styled(Box)(() => ({
    display: 'flex',
    position: 'relative'
}))

const IconWrapper = styled('img')(({ theme }) => ({
    backgroundColor: theme.palette.neutral?.dark,
    opacity: 0.65,
    borderRadius: theme.spacing(9),
    padding: theme.spacing(1),
    zIndex: 100,
    cursor: 'pointer !important',
    position: 'absolute',
    left: '25%',
    top: '15%',
    height: theme.spacing(4)
}))

export {
    RoomTitle,
    Student,
    SelectContainer,
    Avatar,
    StudentItem,
    SelectTextField,
    CustomMenuItem,
    StudentSelectContainer,
    SlideItem,
    SelectFormControl,
    ActivityFilterSelectTextField,
    ActivityFilterMenuItem,
    SelectActivityContainer,
    SelecteActivityTitle,
    Tick,
    IconWrapperTile,
    IconWrapper
}
