import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { Action } from 'hello-design-system'

import { ConferenceContext } from 'contexts/conference'

import { fetchButtonData } from './breakout-rooms/breakout.service'

import { ButtonTexts, DialogContent } from 'utils/constants'

import { CCBRExitDialogComponent } from './dialog'

export const StyledBox = styled(Box)({
    display: 'inline',
    fontWeight: 'bold'
})

export const StyledTypography = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    color: '#2D3748 !important'
}))

const { GoBack, RemoveCC } = ButtonTexts

const AbandonCueCardModal: React.FC<{ route: string; close: () => void }> = ({ close, route }) => {
    const navigate = useNavigate()
    const conference = useContext(ConferenceContext)

    const cueCardForStudent = conference.status === 'session-joined' ? conference.cueCard : []

    const exitCC = (): void => {
        if (conference.status === 'session-joined' && cueCardForStudent) {
            const updatedCueCards = cueCardForStudent.map(data => {
                return {
                    id: data.id,
                    cueCard: null
                }
            })
            conference.setCueCardForStudent(updatedCueCards)
            conference.primus.send('cueCard:add', {
                cueCards: updatedCueCards
            })
        }
        navigate(route)
        close()
    }

    const actions: Action[] = [
        fetchButtonData(GoBack, 'outlined', close),
        fetchButtonData(RemoveCC, 'contained', exitCC)
    ]

    return (
        <>
            <CCBRExitDialogComponent
                open
                onClose={close}
                title={DialogContent.RemoveCC.title}
                actions={actions}
                maxWidth={'sm'}
                showCloseIcon
            >
                <StyledBox>
                    <StyledTypography
                        color='text.primary'
                        gutterBottom
                    >
                        {DialogContent.RemoveCC.body}
                    </StyledTypography>
                </StyledBox>
            </CCBRExitDialogComponent>
        </>
    )
}

export { AbandonCueCardModal }
