import { useContext, useEffect, useState, useRef } from 'react'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'
import { ChangeScreenEvent, PptActionEvent, WhiteboardImportFileEvent, HardMuteEvent, ExpelEvent } from 'primus/types/events'
import { ConferenceContext } from 'contexts/conference'
import { SessionWrapper } from 'components/wrappers'
import { ConnectionStatus } from 'components/status-panels'
import { ZoomArea } from 'components/participants'
import { Sidebar, SidebarProps } from 'components/sidebar'
import { useBreakouts } from 'pages/useBreakouts'
import { TopControls, BottomControls } from './components'
import { ContentWrapper, InnerWrapper } from './components/wrappers'
import { SpeakView, SlidesView, WhiteboardView, CueCardView } from '.'
import { LeaveDialog } from 'components/dialog/LeaveDialog'
import { useTabClose } from 'pages/useTabClose'
import { useLeaveLesson } from 'hooks/useLeaveLesson'
import { useConnectionStatus } from 'hooks/useConnectionStatus'
import { ISpringContext } from 'contexts/ispring'
import * as React from 'react'
import { SnackbarContent } from '@mui/material'
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar'
import { RectangleButton } from 'components/buttons'
import { CloseSnackbar } from 'icons'
import { BookingContext } from 'contexts/booking'
import { getClassResultsViewed } from 'api/unit-assessment/class-results'
import { CustomizedSnackBar } from 'components/snackbar/CustomizedSnackBar'
import { WiFiIcon } from 'components/snackbar/WiFiIcon'
import { WiFiWarningIcon } from 'components/snackbar/WiFiWarningIcon'
import { useNavigateBackDisabled } from 'pages/useNavigateBackDisabled'
import { StudentCueCard } from 'api/classroom/ClassroomState'

interface State extends SnackbarOrigin {
    open: boolean
}

type CueCardDetails = {
    cueCards: StudentCueCard[]
}

const StudentClassroom: React.FC = () => {
    const navigate = useNavigate()
    const { state } = useLocation()
    const breakouts = useBreakouts()
    const conference = useContext(ConferenceContext)
    const { onLeaveHandler } = useLeaveLesson()
    useTabClose()
    const iSpring = useContext(ISpringContext)
    useNavigateBackDisabled()

    const [currentSidebar, setCurrentSidebar] = useState<SidebarProps>({ type: null })
    const [isLeaveDialogOpen, setIsLeaveDialogOpen] = useState(false)
    const [isDiagnosticPage, setIsDiagnosticPage] = useState(false)
    const [innerWrapperHeight, setInnerWrapperHeight] = useState<number>(0)
    const [zoomAreaHeight, setZoomAreaHeight] = useState<number>(0)
    const [topControlsHeight, setTopControlsHeight] = useState<number>(0)
    const [isSpeakMode, setIsSpeakMode] = useState<boolean>(true)
    const topControlsRef = useRef<HTMLDivElement | null>(null)
    const bottomControlsRef = useRef<HTMLDivElement | null>(null)
    const participantsWrapperRef = useRef<HTMLDivElement | null>(null)
    const [bufferedEvent, setBufferedEvent] = useState<PptActionEvent | null>(null)
    const [open, setOpen] = useState(false)
    const booking = useContext(BookingContext)
    const [classResultViewed, setIsClassResultViewed] = useState<boolean | null>(false)
    const [viewNetworkOnlineSnackbar, setNetworkOnlineSnackbar] = useState<boolean>(false)
    const [viewNetworkOfflineSnackbar, setNetworkOfflineSnackbar] = useState<boolean>(false)

    // Function to publish away status
    const publishAwayStatus = (isUserAway: boolean): void => {
        if (conference.status === 'session-joined' && booking.status === 'validated') {
            conference.primus.send('user:away', {
                channel: conference.classroomStateOnJoin.id,
                userId: booking.user.id,
                isAway: isUserAway
            })
        }
    }

    // user enters the tab
    const handleFocus = (): void => {
        publishAwayStatus(false)
    }
    // user away from the tab
    const handleBlur = (): void => {
        publishAwayStatus(true)
    }

    const { connectionAlive } = useConnectionStatus()

    const [states] = useState<State>({
        open: false,
        vertical: 'top',
        horizontal: 'center'
    })

    const { vertical, horizontal } = states

    const importPptHandler = (fileImportEv: WhiteboardImportFileEvent): void => {
        if (iSpring.status === 'ready') {
            iSpring.setPresentationUrl(fileImportEv.url)
        }
    }

    const slideChangeHandler = (pptActionEv: PptActionEvent): void => {
        setBufferedEvent(pptActionEv)
    }

    const hardMuteHandler = (eventData: HardMuteEvent): void => {
        if (conference.status === 'session-joined' && booking.status === 'validated' && booking.user.id === eventData.uid) {
            void conference.rtcSession.updateParticipantsHardMuteStatus(conference.participantHardMuteStatus, eventData.uid, eventData.status)
            void conference.rtcSession.setAudioMuted(eventData.status, true)
        }
    }

    const onCueCardHandler = (ev: CueCardDetails): void => {
        conference.setCueCardForStudent(ev.cueCards)
    }

    useEffect(() => {
        if (conference.status === 'session-joined') {
            conference.primus.on('wb:import-file', importPptHandler)
            conference.primus.on('wb:ppt-action', slideChangeHandler)
            conference.primus.on('remote:mute-mic', hardMuteHandler)
            conference.primus.on('cueCard:add', onCueCardHandler)
        }
    }, [conference.status, iSpring.status])

    const navigateToTeachersScreen = (activeScreen: string): void => {
        switch (activeScreen) {
            case 'avchat':
                return navigate('/classroom')
            case 'slides':
                return navigate('/classroom/slides')
            case 'whiteboard':
                return navigate('/classroom/whiteboard')
            case 'cue-card':
                return navigate('/classroom/cue-card')
            default:
                return void 0
        }
    }

    const toggleLeaveDialog = (): void => {
        setIsLeaveDialogOpen(prevState => !prevState)
    }

    useEffect(() => {
        const { isDiagnostic } = state || { isDiagnostic: false }
        setIsDiagnosticPage(!!isDiagnostic)
    }, [])

    useEffect(() => {
        if (conference.status === 'session-joined') {
            if (connectionAlive) {
                setNetworkOfflineSnackbar(false)
                setNetworkOnlineSnackbar(true)
            } else {
                setNetworkOfflineSnackbar(true)
                setNetworkOnlineSnackbar(false)
                open && setOpen(false)
            }
        }
    }, [connectionAlive])

    useEffect(() => {
        if (conference.status === 'session-joined' && (conference.timeLeft <= 0)) {
            onLeaveHandler(false, '/thanks')
        }
    }, [conference.timeLeft])

    useEffect(() => {
        const changeScreenHandler = (eventData: ChangeScreenEvent): void => navigateToTeachersScreen(eventData.screen)

        if (conference.status === 'session-joined' && booking.status === 'validated') {

            const classResultHandler = (eventData: any): void => {
                const isClassResultViewed = eventData.students.some((student: { id: string }) => {
                    return student.id === booking.user.id
                })
                if (isClassResultViewed) {
                    setOpen(true)
                    setIsClassResultViewed(isClassResultViewed)
                    conference.setClassResults(!isClassResultViewed)
                }
            }
            conference.primus.on('class-results', classResultHandler)

            navigateToTeachersScreen(conference.classroomStateOnJoin.dcAppData.activeScreen)
            conference.primus.on('change-screen', changeScreenHandler)

            const endSessionHandler = (): void => {
                navigate('/thanks')
            }

            const expelSessionHandler = (eventData: ExpelEvent): void => {
                if (conference.status === 'session-joined' && booking.status === 'validated' && booking.user.id === eventData.id) {
                    void conference.rtcSession.studentRemoveStatus()
                    navigate('/expel')
                    conference.primus.end()
                }
            }
            conference.primus.on('end-session', endSessionHandler)
            conference.primus.on('user:expel', expelSessionHandler)
            window.addEventListener('focus', handleFocus)
            window.addEventListener('blur', handleBlur)

            return () => {
                conference.primus.off('end-session', endSessionHandler)
                conference.primus.off('change-screen', changeScreenHandler)
                conference.primus.off('user:expel', expelSessionHandler)
                window.removeEventListener('focus', handleFocus)
                window.removeEventListener('blur', handleBlur)
            }
        }
    }, [conference.status])

    useEffect(() => {
        if (topControlsRef.current && bottomControlsRef.current) {
            const onResize = (): void => {
                if (topControlsRef.current && bottomControlsRef.current) {
                    const height = topControlsRef.current.clientHeight + bottomControlsRef.current.clientHeight
                    setTopControlsHeight(topControlsRef.current.clientHeight)
                    setInnerWrapperHeight(height)
                    setZoomAreaHeight(participantsWrapperRef.current?.clientHeight ?? height)
                }
            }

            const resObs = new ResizeObserver(onResize)
            resObs.observe(topControlsRef.current)
        }
    }, [topControlsRef.current, bottomControlsRef.current, participantsWrapperRef.current])

    useEffect(() => {
        setIsSpeakMode(!participantsWrapperRef.current)
    }, [participantsWrapperRef.current])

    const handleClose = (): any => {
        setOpen(false)
    }

    const viewButtonHandler = (): void => {
        setCurrentSidebar({ type: 'class results' })
        handleClose()
        if (conference.status === 'session-joined' && booking.status === 'validated') {
            void getClassResultsViewed(booking.auth, booking.classroomId, booking.user.id).catch(() => console.log('error'))
        }
    }

    useEffect(() => {
        if (currentSidebar.type === 'class results') {
            handleClose()
        }
    }, [currentSidebar])

    return (
        <SessionWrapper restrictTo='student'>
            <ZoomArea sidebarOpen={!!currentSidebar.type} top={topControlsHeight} height={zoomAreaHeight} isSpeakMode={isSpeakMode} />
            <LeaveDialog isOpen={isLeaveDialogOpen} toggleLeaveDialog={toggleLeaveDialog} />
            <div ref={topControlsRef}>
                <TopControls breakouts={breakouts} toggleLeaveDialog={toggleLeaveDialog}
                    currentSidebar={currentSidebar} setCurrentSidebar={setCurrentSidebar} />
            </div>
            <InnerWrapper height={innerWrapperHeight}>
                <CustomizedSnackBar
                    open={viewNetworkOnlineSnackbar}
                    anchorOrigin={{ vertical, horizontal }}
                    Icon={WiFiIcon}
                    messageTitle='You are back online!'
                    message='Please reload the page.'
                    isActionNeeded={true}
                />
                <CustomizedSnackBar
                    open={viewNetworkOfflineSnackbar}
                    anchorOrigin={{ vertical, horizontal }}
                    Icon={WiFiWarningIcon}
                    messageTitle='Heads up!'
                    message='Your connection seems a little slow. Your audio or video may not be working well.'
                />
                <ContentWrapper sidebarOpen={!!currentSidebar.type}>
                    {(classResultViewed && !viewNetworkOnlineSnackbar) &&
                        <Snackbar
                            anchorOrigin={{ vertical, horizontal }}
                            open={open}
                            sx={{
                                pointerEvents: 'all'
                            }}
                            key={vertical + horizontal}>
                            <SnackbarContent sx={{
                                width: '100%',
                                fontSize: '14px',
                                borderRadius: '16px',
                                boxShadow: '0 30px 60px 0 var(--black-hover-overlay-8), 0 2px 5px 0 rgba(0, 0, 0, 0.02)',
                                background: '#000',
                                padding: '8px 52px 8px 16px',
                                '.MuiSnackbarContent-action': {
                                    paddingLeft: '12px'
                                }
                            }} action={
                                <>
                                    <RectangleButton
                                        id='view-class-result-button'
                                        text=' View class results'
                                        color='error'
                                        onClick={viewButtonHandler}
                                        height='40px'
                                    />
                                    <img src={CloseSnackbar} style={{
                                        cursor: 'pointer',
                                        position: 'absolute',
                                        top: '16px',
                                        right: '18px'
                                    }} onClick={handleClose} />
                                </>
                            }
                            message='Your Teacher has shared your class results with you' />
                        </Snackbar>
                    }
                    {
                        conference.status !== 'session-joined' ? (
                            <ConnectionStatus isDiagnosticPage={isDiagnosticPage} />
                        ) : (
                            <Routes>
                                <Route index element={<SpeakView breakouts={breakouts} />} />
                                <Route path='/slides' element={
                                    <SlidesView
                                        breakouts={breakouts}
                                        wrapperRef={participantsWrapperRef}
                                        bufferedEvent={bufferedEvent}
                                        setBufferedEvent={setBufferedEvent} />
                                } />
                                <Route path='/whiteboard' element={<WhiteboardView breakouts={breakouts} wrapperRef={participantsWrapperRef} />} />
                                <Route path='/cue-card' element={<CueCardView breakouts={breakouts} />} />
                            </Routes>
                        )
                    }
                </ContentWrapper>
                <Sidebar currentSidebar={currentSidebar} setCurrentSidebar={setCurrentSidebar} />
            </InnerWrapper>
            <div ref={bottomControlsRef}>
                <BottomControls currentSidebar={currentSidebar} setCurrentSidebar={setCurrentSidebar} />
            </div>
        </SessionWrapper>
    )
}

export { StudentClassroom }
